<template>
  <div class="base">
    <div class="con">
      <div class="header-tool">
        <div style="display: flex;align-items: center;">
          <div class="header-icon">
            <img src="@/assets/logo.svg" mode="scaleToFill" style="width: 32px;height: 32px" />
          </div>
        </div>
      </div>
      <div class="main">
        <div class="loginContainer">
          <div class="login-title">小樱桃图文编辑器网页版</div>
          <div class="code-container">
            <div class="code-frame">
              <div style="width: 200%;height: 240%;transform: scale(0.5);transform-origin: 0 0;overflow: hidden;">
                <iframe sandbox="allow-top-navigation allow-scripts allow-same-origin allow-forms" :src="authorizeUrl"
                  style="position: absolute;top: -48px;left: 0;width: 100%;height: 100%;border: none;overflow-y: hidden;"
                  scrolling="no"></iframe>
              </div>
            </div>
            <div class="code-title">使用微信扫码登录</div>
          </div>
          <div class="org-avatar">
            <img src="@/assets/ysxz_logo.png" alt class="round" style="width: 100%;height: 100%;" />
          </div>
          <div class="org-title">原声小组</div>
        </div>
      </div>
    </div>
    <div class="text-center copyrightBox">
      <p class="copyright"> 2020 - {{new Date().getFullYear()}} SmartCheery Inc. All Rights Reserved</p>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { api } from "@/utils/api";

const authorizeUrl = ref("");
onMounted(() => {
  wechatLogin();
})
const wechatLogin = () => {
  api().url('/auth/render').get().then((res) => {
    console.log(res.data)
    // window.location.href = res.data;
    // 使用iframe 嵌入
    authorizeUrl.value = res.data;
  })
}

</script>

<style scoped>
.base {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #131416;
}

.con {
  width: 1024px;
  height: 720px;
  padding: 0;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.33);
  background: #434752;
  word-break: break-all;
  white-space: pre-wrap;
}

.main {
  width: 100%;
  height: calc(100% - 52px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.loginContainer {
  width: 640px;
  height: 462px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  margin-bottom: 52px;
}

.login-title {
  color: rgba(0, 0, 0, 0.66);
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  margin-top: 32px;
}

.code-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.code-frame {
  width: 140px;
  height: 140px;
  overflow: hidden;
  background-color: #FFF;
  border-radius: 12px;
  margin-bottom: 12px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.code-title {
  color: rgba(0, 0, 0, 0.66);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.org-avatar {
  width: 64px;
  height: 64px;
  background-color: #FFF;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.org-title {
  color: rgba(0, 0, 0, 0.66);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 20px;
}

.header-tool {
  height: 52px;
  background: #444751;
  padding: 0 10px 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header-icon {
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px dashed #828282; */
  border-radius: 8px;
  background: rgba(224, 224, 224, 0.12);
}

.copyrightBox {
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 16px;
  width: 380px;
}

.copyright {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  /* or 145% */
  color: rgba(255, 255, 255, 0.7);
}
</style>
