<template>
  <div>
    <div
      style="width: 100%; height: 130px"
    ></div>
    <div
      class="row-box"
      :style="
        articleEditStore.curParts.length == 0
          ? ''
          : articleEditStore.checkedAddIndex !== ''
          ? 'position: absolute;right: 0;left: 0;bottom: 110px;margin: auto;z-index: 1111;'
          : 'display:none'
      "
    >
      <div class="text-row">
        <span class="text">添加段落</span>
      </div>
      <div class="icon-row">
        <div class="icon-sub pointer" @click.stop="insertTextBtn">
          <div class="btn-icon-box">
            <img src="@/assets/article/textIcon.png" class="btn-icon" />
          </div>
          <div class="icon-sub-text">文字</div>
        </div>
        <div class="pointer">
          <a-upload
            action="/"
            :multiple="true"
            :limit="9"
            :auto-upload="false"
            ref="uploadImageRef"
            accept=".jpg, .jpeg, .png"
            :show-file-list="false"
            :show-remove-button="false"
            :show-retry-button="false"
            :show-cancel-button="false"
            :on-before-upload="uploadImage"
          >
            <template #upload-button>
              <div class="icon-sub" style="padding-top: 2px">
                <img src="@/assets/article/imgIcon.png" class="btn-img-icon" />
                <div class="icon-sub-text">图片</div>
              </div>
            </template>
          </a-upload>
        </div>
        <div class="pointer">
          <a-upload
            action="/"
            :multiple="true"
            :limit="1"
            :auto-upload="false"
            ref="uploadVideoRef"
            accept=".mp4"
            :show-file-list="false"
            :show-remove-button="false"
            :show-retry-button="false"
            :show-cancel-button="false"
            :on-before-upload="uploadVideo"
          >
            <template #upload-button>
              <div class="icon-sub">
                <div class="btn-icon-box">
                  <img src="@/assets/article/videoIcon.png" class="btn-icon" />
                </div>
                <div class="icon-sub-text">视频</div>
              </div>
            </template>
          </a-upload>
        </div>
      </div>
    </div>
    <div class="bg-row-box" :style="
        articleEditStore.curParts.length == 0
          ? ''
          : articleEditStore.checkedAddIndex !== ''
          ? 'position: fixed;top:0;right: 0;left: 0;bottom: 0;z-'
          : 'display:none'
      ">
      <div @click.stop="closeTab" style="height: 100%;"></div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useArticleEditStore } from "@/store/articleEdit";
import { Message } from "@arco-design/web-vue";
import { uploadRes, uploadVideoCover } from "@/utils/cos";
import { getVideoCover } from "@/utils/partTools";

const articleEditStore = useArticleEditStore();
onMounted(() => {});
// 插入段落
const insertTextBtn = () => {
  if(articleEditStore.checkedAddIndex){
    let _idx = articleEditStore.checkedAddIndex.slice(0, -1) 
    let _type = articleEditStore.checkedAddIndex.charAt(articleEditStore.checkedAddIndex.length-1) 
    articleEditStore.selectPart(_idx);
    if(_type == '1'){
      articleEditStore.insertTextPart(-1, null);
    }else{
      articleEditStore.insertTextPart(_idx, null);
    }
  }else{
    articleEditStore.appendTextPart(null);
  }
};
const closeTab = () => {
  articleEditStore.checkedAddIndex = "";
};
const uploadImage = async (file) => {
  articleEditStore.loading = true;
  if (file.size >= 1024 * 1024) {
    Message.error("图片文件最大上传为1MB;当前文件已超过大小");
  }
  let rst = await uploadRes(1, file);
  if (rst.statusCode === 200) {
    Message.success("资源上传成功");
    let url = "https://" + rst.Location;
    articleEditStore.appendImagePart(url);
  } else {
    Message.error("资源上传错误");
  }
  articleEditStore.loading = false;
};
const uploadVideo = async (file) => {
  articleEditStore.loading = true;
  if (file.size >= 20 * 1024 * 1024) {
    Message.error("视频文件最大上传为20MB;当前文件已超过大小");
  }
  let rst = await uploadRes(1, file);
  if (rst.statusCode === 200) {
    Message.success("视频上传成功");
    let mp4Url = "https://" + rst.Location;
    getVideoCover(mp4Url, (file) => {
      birthVideoCover(mp4Url, file);
    });
  } else {
    Message.error("视频上传错误");
  }
  articleEditStore.loading = false;
};
const birthVideoCover = async (mp4Url, file) => {
  let rst = await uploadVideoCover(1, file);
  if (rst.statusCode === 200) {
    Message.success("封面上传成功");
    let coverUrl = "https://" + rst.Location;
    articleEditStore.appendVideoPart(mp4Url, coverUrl);
  } else {
    Message.error("封面上传错误");
  }
};
</script>

<style scoped>
.row-box {
  width: 348px;
  height: 128px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
}
.text-row {
  width: 100%;
  padding-top: 12px;
}
.text {
  color: #4f4f4f;
  text-align: center;
  font-size: 10px;
  font-weight: 400;
}
.icon-row {
  width: 348px;
  height: 128px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: -10px;
}
.icon-sub {
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.btn-icon-box {
  width: 48px;
  height: 36px;
  padding-top: 6px;
}
.btn-icon {
  width: 38px;
  height: 28px;
  margin-bottom: 18px;
}
.btn-img-icon {
  width: 48px;
  height: 48px;
  margin-top: 4px;
}
.icon-sub-text {
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  margin-top: 8px;
}
</style>
