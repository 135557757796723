<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="header-tool">
    <div style="display: flex;align-items: center;">
      <div class="header-icon">
        <img src="@/assets/logo.svg" mode="scaleToFill" style="width: 32px;height: 32px" />
      </div>
      <div class="header-user-logo">
        <img class="header-user-img" :src="userStore.avatar" mode="scaleToFill" style="width: 100%;height: 100%" />
      </div>
      <p class="header-user-nick">{{ userStore.nick }}</p>
    </div>
    <span class="header-title">我的文档 / #全部</span>
    <div class="btn pointer" @click.stop="addNew">新建文档</div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useArticleListStore } from "@/store/articleList";
import { useUserStore } from "@/store/user";
const articleListStore = useArticleListStore();
const userStore = useUserStore();
const addNew = () => {
  articleListStore.addNewArticle();
};
onMounted(() => { });
</script>

<style scoped>
.header-tool {
  height: 52px;
  background: #444751;
  padding: 0 10px 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header-icon {
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px dashed #828282; */
  border-radius: 8px;
  background: rgba(224, 224, 224, 0.12);
}

.header-user-logo {
  width: 28px;
  overflow: hidden;
  height: 28px;
  box-sizing: border-box;
  overflow: hidden;
  background: #445365;
  border: 2px solid #bfc5ca;
  border-radius: 50%;
  margin-left: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.header-user-img {
  width: 100% !important;
  height: 100% !important;
}

.header-user-nick {
  font-size: 14px;
  color: white;
  font-weight: 500;
  margin-left: 12px;
}

.header-title {
  width: 106px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.btn {
  color: rgba(0, 0, 0, 0.66);
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  padding: 6px 11px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
}
</style>
