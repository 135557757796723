<template>
  <div class="right-con">
    <div class="right-list-con">
      <a-spin :loading="articleListStore.artLoading" tips="正在请求数据，请稍后" style="height: 100%;">
        <a-scrollbar
          type="track"
          outer-class="outer-class"
          v-if="articleListStore.curArticle != null"
        >
          <div class="right-list-display-con">
            <TitlePreview :item="articleListStore.curArticle"></TitlePreview>
            <div
              v-for="(item, index) in articleListStore.curParts"
              :key="index"
            >
              <TextPreview v-if="item.tp === 'text'" :item="item"></TextPreview>
              <ImagePreview
                v-if="item.tp === 'image'"
                :item="item"
              ></ImagePreview>
              <VideoPreview
                v-if="item.tp === 'video'"
                :item="item"
              ></VideoPreview>
            </div>
          </div>
        </a-scrollbar>
        <span
          class="right-empty-tips"
          v-if="articleListStore.curArticle == null"
          >无预览</span
        >
      </a-spin>
    </div>
    <RightConFoot
      v-if="articleListStore.curArticle != null"
      @onShowModal="onShowModal"
    ></RightConFoot>
    <a-modal
      :visible="isShowModal"
      @ok="onOk"
      @cancel="onCancel"
      okText="确定"
      cancelText="取消"
      unmountOnClose
    >
      <template #title>删除确认</template>
      <div>您确定删除该文章么？删除后该文章将不可恢复。请慎重选择。</div>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useArticleListStore } from "@/store/articleList";
import RightConFoot from "@/pages/co/listCo/right_con_foot.vue";
import TitlePreview from "@/pages/co/listCo/preview/title_preview.vue";
import TextPreview from "@/pages/co/listCo/preview/text_preview.vue";
import ImagePreview from "@/pages/co/listCo/preview/image_preview.vue";
import VideoPreview from "@/pages/co/listCo/preview/video_preview.vue";

const articleListStore = useArticleListStore();
const isShowModal = ref(false);
const onShowModal = () => {
  isShowModal.value = true;
};
const onOk = () => {
  isShowModal.value = false;
  articleListStore.delArticle();
};
const onCancel = () => {
  isShowModal.value = false;
};
onMounted(() => {});
</script>

<style scoped>
.right-con {
  width: 700px;
  height: 100%;
  margin-left: 37px;
  background: #fff;
  border-radius: 16px 16px 0px 0px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow-x: hidden;
}
.outer-class {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 16px 16px 0px 0px;
}
.right-list-con {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-empty-tips {
  color: rgba(0, 0, 0, 0.33);
  font-size: 13px;
  font-weight: 500;
  margin-top: 200px;
  text-align: center;
}

.right-list-display-con {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 64px;
  background: #fff;
  border-radius: 16px 16px 0px 0px;
}
</style>
