<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div v-loading="loading" class="social-callback"></div>
</template>
<script setup>
import { nextTick, onMounted, ref } from "vue";
import { api } from "@/utils/api";
import { useUserStore } from "@/store/user";
import router from "@/router/index";
import { useRoute } from "vue-router";
import { Message } from "@arco-design/web-vue";

const userStore = useUserStore();

const route = useRoute();
const loading = ref(true);

/**
 * 接收Route传递的参数
 * @param {Object} route.query.
 */
const code = ref('');
const state = ref('');

const processResponse = async (res) => {
  if (res.code !== 200 && res.code !== 'ok') {
    throw new Error(res.msg);
  }
  if (res.data !== null) {
    userStore.id = res.data.id;
    userStore.nick = res.data.nick;
    userStore.avatar = res.data.avatar;
    userStore.phone = res.data.phone;
    userStore.token = null;
    Message.success("成功登录");
  }
  setTimeout(() => {
    router.push({ name: 'articleList' });
  }, 2000);
};

const handleError = (error) => {
  Message.error(error.message);
};

const callbackByCode = async (data) => {
  try {
    const res = await api().url('/auth/callback').post(data);
    await processResponse(res);
    loading.value = false;
  } catch (error) {
    handleError(error);
  }
};

const init = async () => {

  const data = {
    code: code.value,
    authorization_code: code.value,
    auth_code: code.value,
    state: state.value,
  };

  await callbackByCode(data);
};

onMounted(() => {
  code.value = route.query.code;
  state.value = route.query.state;
  nextTick(() => {
    init();
  });
});
</script>
