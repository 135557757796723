<template>
  <a-spin :loading="articleListStore.loading" tips="正在请求数据，请稍后">
    <div class="base">
      <div class="con">
        <Header></Header>
        <div class="main">
          <LeftCon></LeftCon>
          <RightCon></RightCon>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script setup>
import { onMounted } from 'vue'
import { useArticleListStore } from "@/store/articleList"
import Header from "@/pages/co/listCo/header.vue";
import LeftCon from "@/pages/co/listCo/left_con.vue";
import RightCon from "@/pages/co/listCo/right_con.vue";

const articleListStore = useArticleListStore();

onMounted(() => {
  articleListStore.init()
});

</script>

<style scoped>
.base {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #131416;
}

.con {
  width: 1024px;
  height: 720px;
  padding: 0;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.33);
  background: #434752;
  word-break: break-all;
  white-space: pre-wrap;
}

.main {
  width: 100%;
  height: calc(100% - 52px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
</style>
